export const aricles = [
  {
    title: 'Mother Earth Hosts Our Travels',
    subtitle:
      'Instead of constantly worrying about when your home will be in demand, just share your availability and we will price based on popularity.',
  },
  {
    title: 'Maui Hotel Or Maui Condo',
    subtitle:
      'Instead of constantly worrying about when your home will be in demand, just share your availability and we will price based on popularity.',
  },
  {
    title: 'Wine Tours La Dolce Vita',
    subtitle:
      'Instead of constantly worrying about when your home will be in demand, just share your availability and we will price based on popularity.',
  },
];

export const featuredProperties = [
  {
    image: {
      src: 'https://assets.maccarianagency.com/the-front/photos/rental/1.jpg',
    },
    price: '$320,000.00',
    location: 'Beverly Hills, CA',
    address: '272 S Rexford Dr, Beverly Hills, CA 90212, USA',
    size: '2400 Sq Ft',
    garages: 2,
    baths: 2,
    rooms: 3,
  },
  {
    image: {
      src: 'https://assets.maccarianagency.com/the-front/photos/rental/2.jpg',
    },
    price: '$320,000.00',
    location: 'Beverly Hills, CA',
    address: '272 S Rexford Dr, Beverly Hills, CA 90212, USA',
    size: '2400 Sq Ft',
    garages: 2,
    baths: 2,
    rooms: 3,
  },
  {
    image: {
      src: 'https://assets.maccarianagency.com/the-front/photos/rental/3.jpg',
    },
    price: '$320,000.00',
    location: 'Beverly Hills, CA',
    address: '272 S Rexford Dr, Beverly Hills, CA 90212, USA',
    size: '2400 Sq Ft',
    garages: 2,
    baths: 2,
    rooms: 3,
  },
  {
    image: {
      src: 'https://assets.maccarianagency.com/the-front/photos/rental/4.jpg',
    },
    price: '$320,000.00',
    location: 'Beverly Hills, CA',
    address: '272 S Rexford Dr, Beverly Hills, CA 90212, USA',
    size: '2400 Sq Ft',
    garages: 2,
    baths: 2,
    rooms: 3,
  },
  {
    image: {
      src: 'https://assets.maccarianagency.com/the-front/photos/rental/5.jpg',
    },
    price: '$320,000.00',
    location: 'Beverly Hills, CA',
    address: '272 S Rexford Dr, Beverly Hills, CA 90212, USA',
    size: '2400 Sq Ft',
    garages: 2,
    baths: 2,
    rooms: 3,
  },
  {
    image: {
      src: 'https://assets.maccarianagency.com/the-front/photos/rental/6.jpg',
    },
    price: '$320,000.00',
    location: 'Beverly Hills, CA',
    address: '272 S Rexford Dr, Beverly Hills, CA 90212, USA',
    size: '2400 Sq Ft',
    garages: 2,
    baths: 2,
    rooms: 3,
  },
];

export const places = [
  {
    image: {
      src: 'https://assets.maccarianagency.com/the-front/photos/rental/los-angeles.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/rental/los-angeles@2x.jpg 2x',
    },
    location: 'Los Angeles',
    properties: 24,
    cols: 1,
  },
  {
    image: {
      src: 'https://assets.maccarianagency.com/the-front/photos/rental/san-francisco.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/rental/san-francisco@2x.jpg 2x',
    },
    location: 'San Francisco',
    properties: 19,
    cols: 3,
  },
  {
    image: {
      src: 'https://assets.maccarianagency.com/the-front/photos/rental/seattle.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/rental/seattle@2x.jpg 2x',
    },
    location: 'Seattle',
    properties: 32,
    cols: 3,
  },
  {
    image: {
      src: 'https://assets.maccarianagency.com/the-front/photos/rental/san-diego.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/rental/san-diego@2x.jpg 2x',
    },
    location: 'San Diego',
    properties: 15,
    cols: 1,
  },
];

export const reviews = [
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/veronica-adams@2x.jpg 2x',
    },
    authorName: 'Veronica Adams',
    authorOccupation: 'Growth Marketer, Crealytics',
    feedback:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  },
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/akachi-luccini@2x.jpg 2x',
    },
    authorName: 'Akachi Luccini',
    authorOccupation: 'Lead Generation, Alternative Capital',
    feedback:
      'Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
  },
  {
    authorPhoto: {
      src: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith.jpg',
      srcSet: 'https://assets.maccarianagency.com/the-front/photos/people/jack-smith@2x.jpg 2x',
    },
    authorName: 'Jack Smith',
    authorOccupation: 'Head of Operations, Parkfield Commerce',
    feedback:
      'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  },
];

export const partners = [
  {
    name: 'Airbnb',
    logo: 'https://assets.maccarianagency.com/the-front/logos/airbnb.svg',
  },
  {
    name: 'Coinbase',
    logo: 'https://assets.maccarianagency.com/the-front/logos/coinbase.svg',
  },
  {
    name: 'Dribbble',
    logo: 'https://assets.maccarianagency.com/the-front/logos/dribbble.svg',
  },
  {
    name: 'Instagram',
    logo: 'https://assets.maccarianagency.com/the-front/logos/instagram.svg',
  },
  {
    name: 'Netflix',
    logo: 'https://assets.maccarianagency.com/the-front/logos/netflix.svg',
  },
  {
    name: 'Pinterest',
    logo: 'https://assets.maccarianagency.com/the-front/logos/pinterest.svg',
  },
];

export const advantages = [
  {
    title: 'Mother Earth Hosts Our Travels',
    subtitle:
      'Instead of constantly worrying about when your home will be in demand, just share your availability and we will price based on popularity.',
  },
  {
    title: 'Maui Hotel Or Maui Condo',
    subtitle:
      'Instead of constantly worrying about when your home will be in demand, just share your availability and we will price based on popularity.',
  },
  {
    title: 'Wine Tours La Dolce Vita',
    subtitle:
      'Instead of constantly worrying about when your home will be in demand, just share your availability and we will price based on popularity.',
  },
  {
    title: 'Mother Earth Hosts Our Travels',
    subtitle:
      'Instead of constantly worrying about when your home will be in demand, just share your availability and we will price based on popularity.',
  },
];
